import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  SectionVegetus,
  SectionCities,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import LeaderBoardAdsense from '../../components/LeaderBoardAdsense/LeaderBoardAdsense';
import { FormattedMessage } from '../../util/reactIntl';
import { TopbarContainer } from '../../containers';
import SectionCarousel from '../../components/SectionHowItWorks/SectionCarousel';
import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >      
      <LayoutSingleColumn>
      <LayoutWrapperTopbar transparent={false}>
          <TopbarContainer transparent={false}/>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
        <center>
          <p className={css.quote}> Conecta con gente que tiene salas </p>
         {/*} "¡Bienvenido a Hours, tu marketplace para alquilar espacios por horas! 
          Conectamos a anfitriones con personas que buscan el lugar perfecto para su próximo evento, 
          reunión o entrenamiento. Ofrecemos una amplia variedad de espacios, desde salas de reuniones hasta 
          amplias salas de entrenamiento y naves industriales. Además, ofrecemos servicios de catering para 
          nuestros anfitriones para que tu evento sea aún mejor. Explora nuestra selección de espacios y 
          comienza a planificarte." */}

          {/* <h1 className={css.title}>
            <FormattedMessage id="SectionCarousel.title" />
          </h1> */}
        </center>
        {/* THAT IS TOP SECTION WITH ICON CAROUSEL OF CATEGORIES 
          <SectionCarousel history={history} />
        --- END OF SECTION --- */}
          {/* <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div> */}
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations />
              </div>
            </li>
            {/* Comentant nou Component per posar les tres ciutats principals: Barcelona, Madrid, Valencia }
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionCities />
              </div>
            </li>
            */}
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                  history={history} 
                />
              </div>
            </li>
            
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionVegetus 
                 currentUserListing={currentUserListing}
                 currentUserListingFetched={currentUserListingFetched}
                 history={history} 
                /> 

              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
