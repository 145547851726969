import React, { Component } from 'react';
import PropTypes from 'prop-types';

const googleAdId = 'ca-pub-9518574546292408';

class LeaderBoardAdsense extends Component {
  googleInit = null;

  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({})
    console.log("-------------> DINS DE GOOGLE ADSENSE LEADERBOARD ADSENSE");
   }

  componentWillUnmount() {
    if (this.googleInit) clearTimeout(this.googleInit);
  }

  render () {
    return(
        <div>
        <ins className = "adsbygoogle"
                style = { {display:"inline-block",width:"728px",height:"90px"} }
                data-ad-client = "ca-pub-9518574546292408"
                data-ad-slot = "4452030769"
                data-ad-format="auto"
                data-adtest="on" // <- this one
                data-full-width-responsive="true" >
                  
        </ins>
        </div>
        )
    }
}

LeaderBoardAdsense.propTypes = {
  classNames: PropTypes.string,
  slot: PropTypes.string,
  timeout: PropTypes.number,
};

LeaderBoardAdsense.defaultProps = {
  classNames: '',
  timeout: 200,
};

export default LeaderBoardAdsense;
